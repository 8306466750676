<template lang="pug">
  .price-table-item-form
    AppOverlayLoader(:state="priceTableLoading")
    ArrowBack(@back="$emit('back')")
    .title
      span {{ $t("company_system.rate_plan_settings.price_table.price_table_form.details") }}
    .form
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.price_table.price_table_form.name')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          BFormInput(
            type="text"
            name="name"
            :class="{ 'is-invalid': $v.priceTable.name.$error }"
            v-model="priceTable.name"
            :placeholder="$t('company_system.rate_plan_settings.price_table.price_table_form.name')"
          )
          span.text-length(
            :class="{ 'invalid': !$v.priceTable.name.maxLength }"
          )
            | {{ nameLabel }}
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.price_table.price_table_form.rental_time_classification')")
          span.required {{ $t('company_system.req') }}
        .form-row-field.period-types
          AppDropdown(
            :disabled="isEdit"
            searchable
            close-on-select
            allow-empty
            :class="{ 'is-invalid': $v.priceTable.time_slot_id.$error }"
            value-key="id"
            title-key="name"
            :value="selectedTimeSlot"
            :items="timeSlots"
            @select="selectTimeSlot"
          )
      .form-row
        .form-row-label
          FormFieldLabel(:title="$t('company_system.rate_plan_settings.price_table.price_table_form.price_table')")
          span.required {{ $t('company_system.req') }}
        .form-row-field
          FormTable(
            v-if="!isEmpty(selectedTimeSlot)"
            :slot-type="selectedTimeSlot.type"
            :price-keys="priceKeys"
            :price-rows="$v.priceTable.price_rows"
            :car-classes="carClasses"
            @add-row="addPriceRow"
            @remove-added-row="removeAddedPriceRow"
            @update-row="updatePriceRow"
          )
    FormActionBar(
      :delete-enabled="isEdit"
      @delete="removePriceTable"
      @save="handleSave"
    )
</template>

<script>
  // misc
  import { find, isEmpty, range, map, reduce, cloneDeep } from "lodash-es"
  import { defaultPriceTableObject } from "./../helpers"
  import { NAME_MAX_LENGTH } from "./constants"
  import { DAY_TIME_SLOT_TYPE } from "./../../BasicSettings/constants"
  import { validationMixin } from "vuelidate"
  import priceTableValidator from "./validator"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withScrollTop from "@/mixins/withScrollTop"
  import withConfirmation from "@/mixins/withConfirmation"

  // store modules
  import priceTableModule from "@/config/store/company_system/rate_plan_settings/price_table"

  const priceTableMixin = withStoreModule(priceTableModule, {
    name: "priceTables",
    readers: {
      priceTableItem: "item",
      priceTableLoading: "loading"
    },
    actions: {
      fetchPriceTable: "FETCH_ITEM",
      createPriceTable: "CREATE_ITEM",
      updatePriceTable: "UPDATE_ITEM",
      deletePriceTable: "DELETE_ITEM"
    }
  })

  export default {
    components: {
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      FormActionBar: () => import("../../FormActionBar"),
      FormTable: () => import("./FormTable"),
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [priceTableMixin, validationMixin, withScrollTop, withConfirmation],

    props: {
      priceTableId: {
        type: Number,
        required: false
      },
      timeSlots: {
        type: Array,
        default: () => []
      },
      carClasses: {
        type: Array,
        default: () => []
      }
    },

    validations() {
      return {
        priceTable: priceTableValidator(this.priceKeys)
      }
    },

    mounted() {
      if (this.isEdit) {
        this.fetchPriceTable(this.priceTableId).then(() => {
          this.priceTable = cloneDeep(this.priceTableItem)
        })
      }
    },

    data() {
      return {
        priceTable: defaultPriceTableObject()
      }
    },

    methods: {
      isEmpty,

      textLength(length, maxLength) {
        return `(${length}/${maxLength})`
      },

      selectTimeSlot({ id }) {
        this.priceTable.time_slot_id = id
        this.priceTable.price_rows = []
      },

      addPriceRow() {
        this.priceTable.price_rows.push({
          car_class_id: null,
          prices: reduce(
            this.priceKeys,
            (obj, key) => {
              obj[key] = null

              return obj
            },
            {}
          )
        })
      },

      updatePriceRow({ index, row }) {
        this.priceTable.price_rows.splice(index, 1, row)
      },

      removeAddedPriceRow(index) {
        this.priceTable.price_rows.splice(index, 1)
      },

      removePriceTable() {
        this.$confirm({
          title: this.$t("company_system.rate_plan_settings.delete_price_table_confirmation"),
          resolve: {
            handler: () => {
              this.deletePriceTable(this.priceTable.id).then(() => {
                this.$emit("back")
              })
            }
          }
        })
      },

      validateAttributes() {
        this.$v.$touch()
      },

      cancelValidation() {
        this.$v.$reset()
      },

      handleSave() {
        this.validateAttributes()
        if (!this.isValid) {
          this.$nextTick(() => {
            this.scrollTo({ target: ".invalid", inline: "center" })
          })
          return
        }
        this.sendRequest().then(() => {
          this.cancelValidation()
          this.$emit("back")
        })
      },

      async sendRequest() {
        if (this.isEdit) {
          await this.updatePriceTable(this.priceTable)
        } else {
          await this.createPriceTable(this.priceTable)
        }
      }
    },

    computed: {
      isEdit() {
        return !!this.priceTableId
      },

      isValid() {
        return !this.$v.$invalid
      },

      priceKeys({ selectedTimeSlot }) {
        if (isEmpty(selectedTimeSlot)) {
          return []
        }

        if (selectedTimeSlot.type === DAY_TIME_SLOT_TYPE) {
          const basicKeys = range(1, selectedTimeSlot.until_day + 1).map(day => `day_${day}`)
          return [...basicKeys, "day_after"]
        } else {
          const hours = map(selectedTimeSlot.hours, "value")
          const basicKeys = hours.map(hour => `hours_${hour}`)
          return [...basicKeys, "hour_after", "day_after"]
        }
      },

      selectedTimeSlot() {
        return find(this.timeSlots, ({ id }) => this.priceTable.time_slot_id === id)
      },

      nameLength() {
        return this.priceTable.name?.length || 0
      },

      nameLabel({ nameLength }) {
        return this.textLength(nameLength, NAME_MAX_LENGTH)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/variables.sass"

  .price-table-item-form
    +styled-inputs

    .is-invalid
      ::v-deep
        .dropdown-toggle
          +default-invalid-input

    margin-bottom: 20px

    .flex-row
      display: flex
      justify-content: left

    .arrow-back
      cursor: pointer
      margin-left: 5px
      margin-top: 10px

      ::v-deep
        svg
          path
            fill: $default-gray-medium

      span
        vertical-align: text-top
        margin-left: 5px
        color: $default-purple
        font-size: 0.9rem

    .title
      margin-top: 20px
      height: 40px
      border-left: 13px solid $default-purple-light
      padding: 10px
      font-weight: 700
      font-size: 16px

    .form
      padding: 5px

      &-row
        display: flex
        flex-direction: column
        margin-top: 20px

        &-label
          font-style: normal
          font-weight: 200
          font-size: 13px
          line-height: 19px

          span.required
            margin-left: 10px
            font-size: 12px
            color: $default-red

        &-field
          margin-top: 5px
          font-weight: 400
          font-size: 16px

          .text-length
            font-size: 12px
            color: $default-gray-medium

            &.invalid
              color: $default-red

          &.period-types
            .app-select
              padding: 0
</style>
